import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

function _500() {
  return (
    <Layout>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          paddingTop: 100,
        }}
      >
        <h1
          style={{
            fontSize: 158,
            color: '#b79a5e',
          }}
        >
          Ops...
        </h1>

        <p
          style={{
            fontSize: 32,
            color: '#c9c9c9',
          }}
        >
          Parece que ocorreu um erro interno. <br />
          Por favor, volte à{' '}
          <Link to="/" style={{ color: '#b79a5e' }}>
            página inicial
          </Link>{' '}
          e tente novamente.
        </p>
      </div>
    </Layout>
  )
}

export default _500
